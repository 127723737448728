<template>
    <div class="access-control">
        <transition name="popup">
            <div class="popup-mask" v-if="isShowPopup == true" @click.self="clearFile()">
                <div class="popup-content">
                    <i class="close bi bi-x" @click="clearFile()"></i>
                    <div class="title">Access Control</div>
                    <div class="content">
                        <div class="file-info">
                            <div class="file-detail">
                                <div class="dept-manage">
                                    <h6>My Department</h6>
                                    <div class="dept-manage-list">
                                        <div class="dept-input">
                                            <input type="text" placeholder="new department" v-model="newGroupDept">
                                            <button class="button add-button">+</button>
                                        </div>
                                        <div class="dept-list">
                                            <ul>
                                                <li class="item" v-for="(item, index) in groupList" :key="index">{{ item.dept }} &nbsp;&nbsp;<i class="bi bi-x-lg"></i></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="user-manage">
                                    <h6>User management</h6>
                                    <div class="table">
                                        <div class="table-row-0 row">
                                            <div class="table-cell col-1">User ID</div>
                                            <div class="table-cell col-3"><input></div>
                                            <div class="table-cell col-2"><select v-model="selectedGroupDept">
                                                    <option v-for="group in groupList" :key="group.id">{{ group.dept }}
                                                    </option>
                                                </select></div>
                                            <div class="table-cell col-2"><select v-model="selectedGroupLevel">
                                                    <option v-for="level in availableGroupLevels" :key="level"
                                                        :value="level"> {{ level }}
                                                    </option>
                                                </select></div>
                                            <div class="table-cell col-2"><select v-model="selectedGroupActive">
                                                <option>
                                                    on
                                                </option>
                                                <option>
                                                    off
                                                </option>
                                            </select></div>
                                            <div class="table-cell col-2"><button>+ Create</button></div>
                                        </div>
                                        <div class="table-row-1 row">
                                            <div class="table-cell col-1">Cell 1</div>
                                            <div class="table-cell col-3">Cell 2</div>
                                            <div class="table-cell col-2">Cell 3</div>
                                            <div class="table-cell col-2">Cell 4</div>
                                            <div class="table-cell col-2">Cell 5</div>
                                            <div class="table-cell col-2">Cell 6</div>
                                        </div>
                                        <div class="table-row row">
                                            <div class="table-cell col-1">Cell 1</div>
                                            <div class="table-cell col-3">Cell 2</div>
                                            <div class="table-cell col-2">Cell 3</div>
                                            <div class="table-cell col-2">Cell 4</div>
                                            <div class="table-cell col-2">Cell 5</div>
                                            <div class="table-cell col-2">Cell 6</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="button-list">
                                    <div class="button cancel-button" @click="clearFile()">Cancel</div>
                                    <div :class="{ 'button': true, 'disable-button': this.isUploading }"
                                        @click="saveFile(fileData)">Save</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import api from '../../api/api.js';

export default {
    name: 'accessControl',
    data() {
        return {
            isShowPopup: true,

            doc_id: 0,
            privateFlag: false,
            currentGroupList: [],

            newGroupDept: '',
            groupList: [],
            selectedGroupDept: null,
            selectedGroupLevel: null,
            selectedGroupActive: null,

            isUploading: false,
        };
    },
    methods: {
        addPermission() {
            if (this.selectedGroupDept && this.selectedGroupLevel) {
                const alreadyExists = this.currentGroupList.some(item =>
                    item.dept === this.selectedGroupDept && item.lvl === this.selectedGroupLevel
                );
                if (!alreadyExists) {
                    this.currentGroupList.push({
                        dept: this.selectedGroupDept,
                        lvl: this.selectedGroupLevel,
                    })
                }
            }
            this.removeSameDeptGroup();
        },

        removeSameDeptGroup() {
            this.currentGroupList = Object.values(this.currentGroupList.reduce((acc, item) => {
                acc[item.dept] = item;
                return acc;
            }, {}));
        },

        removePermission(item) {
            this.currentGroupList = this.currentGroupList.filter(i => i !== item);
        },

        clearFile() {
            this.doc_id = 0;
            this.currentGroupList = [];
            this.selectedGroupDept = '';
            this.selectedGroupLevel = '';
            this.privateFlag = false;

            this.closePopup();
        },

        closePopup() {
            this.isShowPopup = false;
            this.$emit('toggleEditPermissionPopup');
        },

        async saveFile() {
            if (!this.privateFlag && this.currentGroupList.length == 0) {
                alert("please add at least one permission group");
                return;
            }
            if (this.isUploading) {
                return;
            }
            this.isUploading = true;
            const data = {
                "doc_id": this.doc_id,
                "privateFlag": this.privateFlag ? 1 : 0,
                "fileAccessGroups": this.privateFlag ? [] : this.currentGroupList,
            };
            const response = await api.postEditAccessGroup(data);
            if (!response.error) {
                // this.$router.go(0);
                this.isUploading = false;
                this.$emit('refreshList');
                this.closePopup();
            } else {
                console.error(response);
            }
        },

        async getAceessGroupList() {
            const response = await api.getDocumentAceessGroupList();
            if (!response.error && response.data[0].attributes.extra.ricoh) {
                this.groupList = response.data[0].attributes.extra.ricoh.result;

                const uniqueGroups = this.groupList.reduce((acc, item) => {
                    const existingGroup = acc.find(group => group.dept === item.group_dept);
                    if (existingGroup) {
                        existingGroup.lvl.push(item.group_level);
                    } else {
                        acc.push({ dept: item.group_dept, lvl: [item.group_level] });
                    }
                    return acc;
                }, []);
                this.groupList = uniqueGroups;
                console.log(this.groupList);
            }
        },

        async aiGenerateAccessGroup(prompt = '', retry = 0) {
            if (this.isGroupAiLoading == true) {
                return;
            }
            this.isGroupAiLoading = true;
            let response;
            if (prompt === '') {
                response = await api.postAskAccessGroup("help me to generate some different access group. Reply me a json string only. The json should contain a 'dept' field and a 'lvl' field. The 'dept' field should be a string and the 'lvl' field should be a number. For example, the reply should be like this: [{\"dept\": \"HR\", \"lvl\": 1},{\"dept\": \"HR\", \"lvl\": 2},{\"dept\": \"IT\", \"lvl\": 1}]. The maximuim length of the array is 5. The maximuim lvl is 3. Each item should only has one department and one lvl. Reply me a json string only. Your reply should not contain any other text or information.");
            } else {
                response = await api.postAskAccessGroup(prompt);
            }
            this.isGroupAiLoading = false;
            let result;
            if (!response.error) {
                try {
                    const jsonString = response.data[0].attributes.extra.ricoh.result.content.match(/```json({.*})```|({.*})/s)[0];
                    result = JSON.parse("[" + jsonString + "]")[0];

                }
                catch (error) {
                    console.error('JSON parse error');
                }
            } else {
                console.error(response);
            }
            if (result) {
                const filterResult = this.groupList
                    .filter(item => result.access_departments.includes(item.dept) && item.lvl.includes(parseInt(result.access_level)))
                    .map(item => ({ dept: item.dept, lvl: result.access_level }));
                if (filterResult.length > 0) {
                    filterResult.forEach(newMatch => {
                        const alreadyExists = this.currentGroupList.some(item =>
                            item.dept === newMatch.dept && item.lvl === newMatch.lvl
                        );
                        if (!alreadyExists) {
                            this.currentGroupList.push(newMatch);
                        }
                    });
                    this.removeSameDeptGroup();
                } else {
                    if (retry < 3) {
                        console.log('No new matching access group found. Trying. Automatically regenerating ' + (retry + 1) + '...');
                        this.aiGenerateAccessGroup(prompt, retry + 1);
                    } else {
                        alert('No new matching access group found. You can try to generate again.');
                    }
                }
            }
        },
    },

    computed: {
        availableGroupLevels() {
            if (this.selectedGroupDept !== null) {
                const selectedGroup = this.groupList.find(
                    (group) => group.dept === this.selectedGroupDept
                );
                return selectedGroup ? selectedGroup.lvl : [];
            }
            return [];
        },
    },

    mounted() {
        this.getAceessGroupList();
    },
}
</script>

<style scoped>
.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.popup-mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
}

.popup-mask .popup-content {
    position: absolute;
    width: 30%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;
    z-index: 1000;
    border-radius: 30px;
    padding: 20px;
    min-width: 50%;
    max-height: 100%;
    overflow-y: scroll;
}

.popup-mask .popup-content::-webkit-scrollbar {
    display: none;
}

.popup-mask .popup-content .close {
    border-radius: 40px;
    width: 18px;
    font-size: 20px;
    float: right;
    color: gray;
    position: absolute;
    right: 23px;
    top: 13px;
    cursor: pointer;
}

.popup-mask .popup-content .title {
    margin: 20px 0;
    font-weight: bold;
    font-size: 15px;
}

.content .file-info {
    width: 100%;
    position: relative;
    padding: 0 10px;
}

.content .file-detail h6 {
    margin: 0;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: bold;
}

.content .file-detail .button {
    border-radius: 20px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    background-color: #F58A32;
    padding: 0px 10px;
    border: unset;
    width: 20%;
    margin: 5px 5px;
    line-height: 35px;
    font-weight: bold;
}

.content .file-detail .button:hover {
    background-color: #F05A27;
}

.content .file-detail .button:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    transform: scale(0.99);
}

.content .file-detail .button.disable-button {
    background-color: darkgray;
    cursor: default;
}

.content .file-detail .button.cancel-button {
    background-color: transparent;
    color: gray;
}

.content .file-detail .dept-manage {
    text-align: left;
    margin-bottom: 100px;
}

.content .file-detail .dept-manage .dept-manage-list .add-button {
    padding: 0;
    width: 100px;
    height: 25px;
    margin: 0;
    line-height: normal;
    border-radius: 20px;
}

.content .file-detail .dept-manage .dept-input input {
    width: calc(100% - 100px);
    border: unset;
    outline: unset;
    border-bottom: 1px solid gray;
    font-size: 14px;
}

.content .file-detail .dept-manage .dept-list {
    margin-top: 20px;
}

.content .file-detail .dept-manage .dept-list ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
}

.content .file-detail .dept-manage .dept-list ul li {
    list-style-type: none;
    background-color: peachpuff;
    margin: 0px 8px 5px 0px;
    border-radius: 20px;
    padding: 0 15px;
    color: #F05A27;
    border: 1px solid #F05A27;
    font-size: 12px;
    font-weight: bold;
}

.content .file-detail .dept-manage .dept-list ul li i {
    color: #FFFFFF;
    cursor: pointer;
}

.content .file-detail .user-manage {
    text-align: left;
}

.content .file-detail .user-manage .table {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.content .file-detail .user-manage .table .table-row-0 {
    display: flex;
    font-size: 12px;
}

.content .file-detail .user-manage .table .table-row-0 .table-cell {
    border: 1px solid black;
    padding: 10px;
    align-content: center;
}

.content .file-detail .user-manage .table .table-row-0 input {
    border-radius: 25px;
    height: 25px;
    border: 1px solid gray;
}

.content .file-detail .user-manage .table .table-row-0 select {
    border-radius: 25px;
    height: 25px;
    border: 1px solid gray;
    padding: 0 10px;
}

.content .file-detail .user-manage .table .table-row-0 button {
    padding: 0;
    width: 115px;
    margin: 0;
    line-height: normal;
    border-radius: 20px;
}

.content .file-detail .user-manage .table .table-row-1 {
    display: flex;
}

.content .file-detail .user-manage .table .table-row-1 .table-cell {
    border: 1px solid black;
    padding: 10px;
}

.content .file-detail .user-manage .table .table-row {
    display: flex;
}

.content .file-detail .user-manage .table .table-row .table-cell {
    border: 1px solid black;
    padding: 10px;
}

.content .file-detail .file-detail-privacy {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.content .file-detail .file-detail-privacy .form-switch .form-check-input {
    box-shadow: unset;
    border: 1px solid gray;
    background-color: gray;
    background-image:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28255, 255, 255, 1%29%27/%3e%3c/svg%3e")
}

.content .file-detail .file-detail-privacy .form-switch .form-check-input:checked {
    border: 1px solid #F58A32;
    background-color: #F58A32;
    background-image:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28255, 255, 255, 1%29%27/%3e%3c/svg%3e")
}

.content .file-detail .file-detail-permission {
    text-align: left;
    font-size: 12px;
}

.content .file-detail .file-detail-permission select {
    margin: 0 10px 0 0;
    min-width: 150px;
    border: 1px solid gray;
    border-radius: 15px;
    height: 1.5rem;
    padding: 0px 10px;
}

.content .file-detail .file-detail-permission .add-button {
    padding: 0;
    width: 115px;
    margin: 0;
    line-height: normal;
    border-radius: 20px;
}

.content .file-detail .button-list {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}

.content .file-detail .ai-generate {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
}

.content .file-detail .ai-generate .ai-generate-button {
    border-radius: 20px;
    background-color: crimson;
    color: white;
    padding: 0 10px;
    width: 115px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 12px;
}

.content .file-detail .ai-generate .ai-generate-button:hover {
    background-color: firebrick;
}

.content .file-detail .ai-generate .ai-generate-button:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    transform: scale(0.99);
}

.content .file-detail .ai-generate p {
    margin: 0;
    text-align: left;
    font-size: 12px;
    width: 60%;
}

.loading {
    position: relative;
    width: 20px;
    height: 20px;
}
</style>