<template>
    <div class="change-password">
        <transition name="popup">
            <div class="popup-mask" v-if="isShowPopup == true" @click.self="clearFile()">
                <div class="popup-content">
                    <i class="close bi bi-x" @click="clearFile()"></i>
                    <div class="title">Change password</div>
                    <div class="content">
                        <div class="file-info">
                            <div class="file-detail">
                                <div class="password">
                                    <h6>New password<br><span>(at least 6 characters long and Include both letters and numbers.)</span></h6>
                                    <input type="password" id="newPassword" v-model="newPassword">
                                </div>
                                <div class="password">
                                    <h6>Compfirm password</h6>
                                    <input type="password" v-model="comfirmPassword" />
                                </div>
                                <div class="button-list">
                                    <div class="button cancel-button" @click="clearFile()">Cancel</div>
                                    <div class="button"
                                        @click="saveFile()">Save</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
// import api from '../../api/api.js';

export default {
    name: 'changePassword',
    data() {
        return {
            isShowPopup: true,

            newPassword: '',
            comfirmPassword: '',
        };
    },
    methods: {

        clearFile() {
            this.newPassword = '';
            this.comfirmPassword = '';

            this.closePopup();
        },

        closePopup() {
            this.isShowPopup = false;
            this.$emit('toggleChangePasswordPopup');
        },

        async saveFile() {
            var passwordElement = document.getElementById('newPassword');
            passwordElement.style.borderColor = 'gray';
            if (!this.newPassword) {
                alert("Password should not be empty");
                return;
            }
            if (this.newPassword != this.comfirmPassword) {
                alert("New password and confirmed password do not match");
                return;
            }
            var password = passwordElement.value;
            var regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
            if (!regex.test(password)) {
                passwordElement.style.borderColor = 'red';
                alert("New password should be at least 6 characters long and include both letters and numbers.");
            }
            const data = {
                "newpwd1": this.newPassword,
                "newpwd2": this.comfirmPassword,
            };
            console.log(data);
            alert("Function has not finished yet. Password does not changed.");
            // const response = await api.postChangePassword(data);
            // if (!response.error) {
            //     this.clearFile();
            // } else {
            //     alert("Password change failed");
            //     console.error(response);
            // }
        },
    },

    mounted() {

    },
}
</script>

<style scoped>
.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.popup-mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
}

.popup-mask .popup-content {
    position: absolute;
    width: 30%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;
    z-index: 1000;
    border-radius: 30px;
    padding: 20px;
    min-width: 530px;
    max-height: 100%;
    overflow-y: scroll;
}

.popup-mask .popup-content::-webkit-scrollbar {
    display: none;
}

.popup-mask .popup-content .close {
    border-radius: 40px;
    width: 18px;
    font-size: 20px;
    float: right;
    color: gray;
    position: absolute;
    right: 23px;
    top: 13px;
    cursor: pointer;
}

.popup-mask .popup-content .title {
    margin: 20px 0;
    font-weight: bold;
    font-size: 15px;
}

.content .file-detail .button {
    border-radius: 20px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    background-color: #F58A32;
    padding: 0px 10px;
    border: unset;
    width: 15%;
    margin: 5px 5px;
    line-height: 35px;
    font-weight: bold;
}

.content .file-detail .button:hover {
    background-color: #F05A27;
}

.content .file-detail .button:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    transform: scale(0.99);
}

.content .file-detail .button.disable-button {
    background-color: darkgray;
    cursor: default;
}

.content .file-detail .button.cancel-button {
    background-color: transparent;
    color: gray;
}

.content .file-info {
    width: 100%;
    position: relative;
    top: -15px;
    padding: 0 10px;
}

.content .file-detail h6 {
    margin: 0;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
}

.content .file-detail .password {
    text-align: left;
    margin: 30px 0;
}

.content .file-detail .password span {
    color: gray;
    font-size: 12px;
}

.content .file-detail .password input {
    width: 100%;
    border: unset;
    outline: unset;
    border-bottom: 1px solid gray;
    padding: 5px 5px;
    font-size: 14px;
}

.content .file-detail .file-detail-description {
    margin-top: 15px;
    text-align: left;
}

.content .file-detail .button-list {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}
</style>