<template>

  <body>
    <div v-if="loginStatus != null" id="home" class="container">
      <div class="row">
        <div class="col-1">
          <img class="logo" src="../assets/logo_72.png">
        </div>
        <div class="col-7 upload-bar" @click="toggleUploadPopup">
          <div class="upload-bar-input">Upload new documents</div>
          <i class="bi bi-upload"></i>
        </div>
        <div class="col-4 user-info-bar">
          <a :class="{ 'user-info': true, 'user-info-short': !isManager}" @click="toggleUserInfoPopup">
            <div class="flex-space-around">
              <i class="bi bi-person"></i>
              <p>{{ userEmail }}</p>
            </div>
            <i class="bi bi-chevron-right"></i>
          </a>
          <div v-if="!isManager" class="setting">
            <i class="bi bi-gear"></i>
          </div>
          <userInfo :isShowPopup="isShowUserInfoPopup" :userEmail="userEmail" :userDept="userDept"
            :userLevel="userLevel" @toggleUserInfoPopup="toggleUserInfoPopup" @toggleChangePasswordPopup="toggleChangePasswordPopup"></userInfo>
        </div>
      </div>
      <div class="row main-content">
        <div class="col-8 left-content">
          <div class="row items-list scrollbar">
            <ul class="list-group">
              <div class="loading list-loading" v-if="isListLoading"></div>
              <li class="list-group-item" v-for="item in mediaList" :key="item.id">
                <documentItem :doc_id="item.id" :fileName=item.doc_filename :updateTime=item.last_update_time
                  :title=item.doc_title :content=item.doc_description :privacy="item.doc_private_flag"
                  :group="item.doc_groups_json" :url="item.doc_oss_path" :doc_uid="item.user_id" :uid="userId" @changeDocId="changeDocId"
                  @toggleEditMetaPopup="toggleEditMetaPopup" @toggleEditPermissionPopup="toggleEditPermissionPopup"
                  @toggleDeleteConfirmPopup="toggleDeleteConfirmPopup"></documentItem>
              </li>
            </ul>
          </div>
          <div class="bottom-nav">
            <p>Total: {{ totalItems }} results</p>
            <nav aria-label="Page navigation">
              <ul class="pagination justify-content-end">
                <li class="arrow-button">
                  <a href="#" @click="turnToPage('prev');"><i class="bi bi-chevron-left"></i> Previous</a>
                </li>
                <li v-for="n in totalPages" :key="n"><a href="#" @click="turnToPage('go', n - 1);"
                    :class="{ 'active-page': currentPage === n - 1 }">{{ n }}</a></li>
                <li class="arrow-button">
                  <a href="#" @click="turnToPage('next');">Next <i class="bi bi-chevron-right"></i></a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="col-4">
          <div class="assist-chat-box info-col">
            <div class="assist-name">DocuFinder Assist</div>
            <transition name="fade" mode="out-in">
              <div class="assist-default" v-show="!isOnChat">
                <img src="../assets/portrait.png" />
                <div class="assist-ask">Ask me for the document or context you need</div>
                <div class="quick-option" @click="quickOptionSearch($event.target.textContent)"><i class="bi bi-file-earmark-text-fill"></i><div>請協助搜尋與HKTVmall的財務報告相關的所有文件，包括預算報告和季度分析。</div></div>
                <div class="quick-option" @click="quickOptionSearch($event.target.textContent)"><i class="bi bi-clipboard2-data-fill"></i><div>請查找包含客戶反饋和調查結果的文件，特別是有關送貨速度與產品質量的反饋。</div></div>
                <div class="quick-option" @click="quickOptionSearch($event.target.textContent)"><i class="bi bi-coin"></i><div>請檢索所有與供應商合同和合作協議相關的文件，特別是針對物流和市場推廣的協議。</div></div>
              </div>
            </transition>
            <transition name="fade" mode="out-in">
              <div class="assist-chat" v-show="isOnChat">
                <div class="question-row">
                  <div ref="assistQuestion" class="question scrollbar-hidden"><Markdown :source="questionContentCopy" /></div>
                </div>
                <div class="answer">
                  <img src="../assets/portrait.png" />
                  <div class="answer-text">
                    <div class="loading" v-if="isLoading"></div>
                    <div class="assist-answer scrollbar" ref="assistAnswer">
                      <Markdown :source="answerSource" />
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <div class="ask-me-search">
              <div class="ask-me-search-upper">
                <textarea id="question-content" rows="5" class="scrollbar" v-model="questionContent"></textarea>
              </div>
              <div class="ask-me-search-bottom">
                <div>Ask Me Search</div>
                <div><a @click="clearQuestion"><i class="bi bi-arrow-clockwise"></i></a><a @click="sendQuestion"><i
                      class="bi bi-search"></i></a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <uploadDocument v-if="isShowUploadPopup" @toggleUploadPopup="toggleUploadPopup" @refreshList="getMediaList(currentPage)">
    </uploadDocument>
    <editMeta v-if="isShowEditMetaPopup" :doc="currentDoc" @toggleEditMetaPopup="toggleEditMetaPopup"
      @refreshList="getMediaList(currentPage)"></editMeta>
    <editPermission v-if="isShowEditPermissionPopup" :doc="currentDoc"
      @toggleEditPermissionPopup="toggleEditPermissionPopup" @refreshList="getMediaList(currentPage)"></editPermission>
    <deleteConfirm v-if="isShowDeleteConfirmPopup" :doc="currentDoc"
      @toggleDeleteConfirmPopup="toggleDeleteConfirmPopup" @refreshList="getMediaList(currentPage)"></deleteConfirm>
    <changePassword v-if="isShowChangePasswordPopup" @toggleChangePasswordPopup="toggleChangePasswordPopup"></changePassword>
    <accessControl v-if="false"></accessControl>
  </body>
</template>

<script>
import documentItem from './documentItem.vue';
import uploadDocument from './pop-up/uploadDocument.vue';
import editMeta from './pop-up/editMeta.vue';
import editPermission from './pop-up/editPermission.vue';
import deleteConfirm from './pop-up/deleteConfirm.vue';
import userInfo from './pop-up/userInfo.vue';
import Markdown from 'vue3-markdown-it';
import changePassword from './pop-up/changePassword.vue';
import api from '../api/api.js';
import accessControl from './pop-up/accessControl.vue';
export default {
  name: 'HomePage',
  components: {
    documentItem,
    uploadDocument,
    editMeta,
    editPermission,
    deleteConfirm,
    userInfo,
    Markdown,
    changePassword,
    accessControl
  },
  data() {
    return {
      totalItems: 0,
      mediaList: [],
      currentPage: 0,
      pageSize: 5,
      totalPages: 0,
      islogin: false,

      userEmail: '',
      userDept: '',
      userLevel: '',
      userId: 0,
      isManager: 0,
      companyId: '',

      loginStatus: null,

      isShowUploadPopup: false,
      isShowEditMetaPopup: false,
      isShowEditPermissionPopup: false,
      isShowDeleteConfirmPopup: false,
      isShowUserInfoPopup: false,
      isShowChangePasswordPopup: false,

      currentDocId: 0,
      currentDoc: [],

      isOnChat: false,
      questionContent: '',
      questionContentCopy: '',
      answerContent: '',
      isLoading: false,
      isListLoading: false,
      answerSource: '',

      resultArray: []
    }
  },

  methods: {
    async getMediaList(pageNum) {
      this.currentPage = pageNum;
      this.mediaList = [];
      this.totalItems = 0;
      this.totalPages = 0;
      this.isListLoading = true;
      try {
        const response = await api.getDocumentList(pageNum);
        if (!response.errors) {
          if (response.data[0].attributes.extra.ricoh) {
            this.mediaList = response.data[0].attributes.extra.ricoh.result.docList.data;
            this.totalItems = response.data[0].attributes.extra.ricoh.result.docList.total;
            this.totalPages = Math.ceil(this.totalItems / this.pageSize);
          } else {
            console.error("No Ricoh extra field");
          }
        } else {
          console.error("Fail to get response");
        }
      } catch (error) {
        console.error("Fail to call api");
        console.error(error);
      }
      this.isListLoading = false;
    },

    getDocumentById(docId) {
      return this.mediaList.filter(i => i.id == docId)[0];
    },

    toggleUploadPopup() {
      this.isShowUploadPopup = !this.isShowUploadPopup;
    },

    toggleEditMetaPopup() {
      this.isShowEditMetaPopup = !this.isShowEditMetaPopup;
    },

    toggleEditPermissionPopup() {
      this.isShowEditPermissionPopup = !this.isShowEditPermissionPopup;
    },

    toggleDeleteConfirmPopup() {
      this.isShowDeleteConfirmPopup = !this.isShowDeleteConfirmPopup;
    },

    toggleUserInfoPopup() {
      this.isShowUserInfoPopup = !this.isShowUserInfoPopup;
    },

    toggleChangePasswordPopup() {
      this.isShowChangePasswordPopup = !this.isShowChangePasswordPopup;
    },

    changeDocId(DocId) {
      this.currentDocId = DocId;
      this.currentDoc = this.getDocumentById(this.currentDocId);
    },

    logout() {
      api.logout();
      this.$router.push({ path: '/' });
    },

    getLoginInfo(user) {
      this.userEmail = user.attributes.email;
      this.userDept = user.attributes['doc-department'];
      this.userLevel = user.attributes['doc-level'];
      this.userId = user.attributes.id;
      this.isManager = user.attributes['extra'].is_manager;
      this.companyId = user.attributes['extra'].manager_company_id;
    },

    quickOptionSearch(question) {
      this.questionContent = question;
      this.sendQuestion();
    },

    sendQuestion() {
      if (this.isLoading || this.isListLoading) {
        return;
      }
      if (this.questionContent == '') {
        return;
      }
      this.getAssistAnswer(this.questionContent);
      this.isOnChat = true;
      //this.$refs.assistQuestion.textContent = this.questionContent.replace(/\n/g, '<br>');
      this.questionContentCopy = this.questionContent.replace(/\n/g, '  \n');
      this.questionContent = '';
      this.isLoading = true;
    },

    clearQuestion() {
      if (this.isLoading || this.isListLoading) {
        return;
      }
      this.isOnChat = false;
      this.questionContent = '';
      this.answerContent = '';
      this.isLoading = false;
      this.getMediaList(0);
    },

    turnToPage(mode = 'go', pageNum = 0) {
      if (this.isLoading || this.isListLoading) {
        return;
      }
      if (mode == 'go') {
        if (this.currentPage == pageNum) return;
        this.currentPage = pageNum;
        this.getMediaList(this.currentPage);
      } else if (mode == 'prev') {
        this.currentPage = Math.max(0, this.currentPage - 1);
        this.getMediaList(this.currentPage);
      } else if (mode == 'next') {
        if (this.totalPages > 1) {
          this.currentPage = Math.min(this.totalPages - 1, this.currentPage + 1);
        } else {
          this.currentPage = 0;
        }
        this.getMediaList(this.currentPage);
      }
    },

    async getAssistAnswer(prompt) {
      // this.$refs.assistAnswer.textContent = '';
      this.answerSource = '';
      const response = await api.postSearchByPrompt(prompt);
      if (!response.error && response.data[0].attributes.extra.ricoh) {
        this.isLoading = false;
        this.answerContent = response.data[0].attributes.extra.ricoh.result.aiResponse;
        // this.$refs.assistAnswer.innerHTML = this.answerContent.replace(/\n/g, '<br>');
        this.answerSource = this.answerContent;
        this.mediaList = response.data[0].attributes.extra.ricoh.result.collectionInfos;
        this.totalItems = this.mediaList.length;
        this.totalPages = 1;
      } else {
        console.error(response.error);
      }
    }

  },

  async mounted() {
    this.loginStatus = await api.loginStatusChecker();
    if (this.loginStatus == null) {
      this.$router.push({ path: '/' })
    } else {
      this.getLoginInfo(this.loginStatus);
    }

    this.getMediaList(0);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~bootstrap-icons/font/bootstrap-icons.min.css";

body {
  background: #FFFFFF;
  position: relative;
  cursor: default;
}

#home {
  background: linear-gradient(to left, #71AAE7, #FA9539, #C4E2FE);
  min-height: 100vh;
  padding: 15px 30px 0 30px;
  max-width: unset;
}

#home .main-content .left-content {
  background-color: #FFFFFF;
  padding: 20px 35px 0px 35px;
  border-radius: 20px;
}

.flex-space-around {
  display: flex;
  justify-content: space-around;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: -webkit-box; */
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.logo {
  height: 50px;
  position: initial;
}

.upload-bar {
  display: inline-flex;
  justify-content: flex-end;
  cursor: pointer;
  padding: 0;
}

.upload-bar i {
  position: absolute;
  transform: translate(-25px, 15px);
  color: gray;
}

.upload-bar-input {
  width: 100%;
  height: 50px;
  border-radius: 30px;
  border: 1px solid #afafaf;
  padding-left: 20px;
  text-align: left;
  align-content: center;
  background-color: white;
  color: gray;
  margin-bottom: 0;
  font-size: 14px;
}

.user-info-bar {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.user-info-bar .user-info {
  display: flex;
  background-color: #FFFFFF;
  border-radius: 25px;
  color: gray;
  justify-content: space-between;
  padding: 8px 20px 0px 20px;
  height: 2.5rem;
  margin: 4px 0px;
  cursor: pointer;
  text-decoration: none;
  min-width: fit-content;
}

.user-info-bar .user-info.user-info-short {
  width: 85%;
}

.user-info-bar .user-info div i {
  border-radius: 25px;
  background: linear-gradient(45deg, #29A4A9, #F58A32);
  color: #FFFFFF;
  height: fit-content;
  padding: 0px 4px;
  margin-right: 5px;
}

.user-info-bar .setting {
  margin: 0 auto;
  color: #FFFFFF;
  font-size: 35px;
  cursor: pointer;
}

.list-group-item {
  background-color: unset;
  margin: 0;
  padding: 0;
  border: unset;
  display: table;
}

.items-list {
  height: 80vh;
  overflow-y: scroll;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
}

.scrollbar::-webkit-scrollbar-track {
  background: #C6DEF3;
  border-radius: 10px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #71AAE7;
  border-radius: 10px;
}

.scrollbar::-webkit-scrollbar-thumb:hover,
.scrollbar::-webkit-scrollbar-thumb:active {
  background: #0F89CA;
  border-radius: 10px;
}

.bottom-nav {
  display: flex;
  justify-content: space-between;
  margin: 0 -10px;
  align-items: flex-end;
  margin-top: 10px;
  font-size: 14px;
}

.bottom-nav .pagination {
  display: inline-flex;
}

.bottom-nav .pagination a {
  color: black;
  float: left;
  padding: 3px 9px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  margin: 0 3px;
  border-radius: 5px;
  background-color: white;
}

.bottom-nav .pagination a.active-page {
  background-color: #0F89CA;
}

.bottom-nav .pagination a.active {
  background-color: #4CAF50;
  color: white;
  border: 1px solid #4CAF50;
}

.bottom-nav .pagination .arrow-button a {
  border: unset;
  background-color: unset;
}

.bottom-nav .pagination a:hover:not(.active) {
  background-color: #ddd;
}

.main-content {
  margin-top: 15px;
  padding: 0;
}

.info-col {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 20px;
  border: 1px solid #afafaf;
  min-height: 90vh;
  min-width: fit-content;
  text-align: -webkit-center;
}

.assist-name {
  background: linear-gradient(to left, #71AAE7, #FA9539, #C4E2FE);
  border-radius: 20px 20px 0 0;
  padding: 15px 0 10px 0;
  margin-bottom: 25px;
  font-weight: bold;
  color: #FFFFFF;
}

.assist-ask {
  margin: 50px 0 10px 0;
  font-weight: bold;
  font-size: 14px;
  color: gray;
}

.quick-option {
  background-color: rgba(255, 255, 255, 0.7);
  color: darkgray;
  border: 1px solid darkgray;
  border-radius: 20px;
  margin: 3px 0;
  width: 80%;
  padding: 8px 25px;
  text-align: left;
  display: flex;
  cursor: pointer;
}

.quick-option i {
  color: #F58A32;
  margin: 0 3px;
}

.ask-me-search {
  margin-top: 30px;
  margin-bottom: 10px;
}

.ask-me-search .ask-me-search-upper {
  width: 80%;
  border: 1px solid darkgray;
  border-bottom: unset;
}

.ask-me-search .ask-me-search-upper textarea {
  width: 100%;
  resize: none;
  border: unset;
  background: transparent;
  align-content: end;
}

.ask-me-search .ask-me-search-upper textarea:focus {
  outline: none;
}

.ask-me-search .ask-me-search-bottom {
  width: 80%;
  border: 1px solid darkgray;
  border-top: unset;
  padding: 0 15px 5px 15px;
  display: flex;
  justify-content: space-between;
}

.ask-me-search .ask-me-search-bottom a {
  cursor: pointer;
  margin-left: 10px;
}

.assist-chat .question-row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.assist-chat .question-row i {
  color: white;
  border-radius: 25px;
  background-color: red;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0px 6px;
  font-size: 24px;
}

.assist-chat .question {
  border: 1px solid #F58A32;
  border-radius: 20px 20px 0 20px;
  width: 85%;
  padding: 10px 0;
  font-size: 14px;
  color: gray;
  background-color: whitesmoke;
  margin: 40px 0;
  box-shadow: 2px 3px 5px lightgray;
  overflow-y: scroll;
  max-height: 8vh;
  text-align: left;
}

.assist-chat .question p {
  margin: 0 5px;
}

.assist-chat .answer {
  display: flex;
  justify-content: space-around;
  width: 85%;
  align-items: flex-end;
}

.assist-chat .answer img {
  width: 50px;
  height: 50px;
}

.assist-chat .answer .answer-text {
  width: 85%;
  border: 1px solid lightgray;
  border-radius: 15px 15px 15px 0;
  padding: 10px 2px;
  font-size: 14px;
  color: gray;
  background-color: whitesmoke;
  box-shadow: 2px 3px 5px lightgray;
}

.assist-chat .answer .answer-text .assist-answer {
  text-align: left;
  max-height: 35vh;
  overflow-y: scroll;
}

#home .main-content .assist-chat-box .assist-default img {
  width: 175px;
}

.loading {
  position: relative;
  width: 30px;
  height: 30px;
  border: 2px solid #000;
  border-top-color: rgba(0, 0, 0, 0.2);
  border-right-color: rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(0, 0, 0, 0.2);
  border-radius: 100%;

  animation: circle infinite 0.75s linear;
}

@keyframes circle {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fade-enter-active {
  transition: all 1s;
}

.fade-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.list-loading {
  position: absolute;
  z-index: 1;
  left: 35%;
  top: 40%;
}
</style>
